import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { ICheckoutBody } from '@digisign3-ui/sender/src/store/templateCreate/types';
import { IUserMemberships, IUserSubscription, Trial } from '@digisign3-ui/sender/src/store/pageFrame/types';
import { accountApiUrl } from '../../constants';
import { getAccessToken } from '../../../auth/oktaConfig';
import { headers } from '../common/helper';

export const getUsers = async (limit: number, skip: number, search?: string) => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(
    `${accountApiUrl}/v3/users?${search ? `user.search=${search}&` : ''}page=${skip}&size=${limit}`,
    headers(accessToken)
  );
  return data.result;
};

export const getUsersGroups = async () => {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const { data } = await axios.get(
    `${accountApiUrl}/v3/user/id?Id=${
      claims.delegated_user_id || claims.OktaUserId || claims.uid
    }&groupOption.loadAll=true&groupOption.pageSize=250`,
    headers(accessToken)
  );
  return data.result;
};

export const getGroupUserCounts = async (groups: string[]) => {
  const accessToken = await getAccessToken();
  const requestIds = groups.join();
  const { data } = await axios.get(
    `${accountApiUrl}/v3/groups/find?group.ids=${requestIds}&userOption.loadAll=true`,
    headers(accessToken)
  );
  return data.result;
};

export const getUsersGroupsPaginated = async () => {
  const accessToken = await getAccessToken();
  // we need to be able to get ALL groups for a user, and there is no way to do that currently
  // so we pass in a high number until then
  const { data } = await axios.get(`${accountApiUrl}/v3/groups/find?page=1&size=250`, headers(accessToken));
  if (data.errors.length) {
    return {
      errors: data.errors,
      result: {},
    };
  }
  return data.result;
};

export const getUsersMemberships = async () => {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(`${accountApiUrl}/v3/users/me/subscription`, headers(accessToken));
  return data.result as IUserMemberships;
};

export const checkout = async (body: ICheckoutBody) => {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const res = await axios.post(
    `${accountApiUrl}/v3/users/subscription/checkout`,
    {
      ...body,
      userId: claims.OktaUserId || claims.uid,
    },
    headers(accessToken)
  );
  return {
    result: res.data,
    status: res.status,
  };
};

export const updateUserSubscription = async () => {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const res = await axios.post(
    `${accountApiUrl}/v3/users/${claims.OktaUserId || claims.uid}/subscription`,
    {},
    headers(accessToken)
  );
  return {
    result: res.data,
    status: res.status,
  };
};

export const getStripeSubscription = async () => {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const res = await axios.get<IUserSubscription>(
    `${accountApiUrl}/v3/users/${claims.OktaUserId || claims.uid}/subscription`,
    headers(accessToken)
  );
  return {
    result: res.data,
    status: res.status,
  };
};

export async function getTrial(): Promise<Trial> {
  const accessToken = await getAccessToken();
  const { data } = await axios.get(`${accountApiUrl}/v3/users/me/trial`, headers(accessToken));
  return data.result;
}

export async function createTrial(): Promise<Trial> {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const { data } = await axios.post(
    `${accountApiUrl}/v3/users/${claims.OktaUserId || claims.uid}/trial`,
    {},
    headers(accessToken)
  );

  return data.result;
}

export async function extendTrial() {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const { data } = await axios.post(
    `${accountApiUrl}/v3/users/${claims.OktaUserId || claims.uid}/trial/extension`,
    { envelopesLeft: 5 },
    headers(accessToken)
  );
  if (data.errors.length) {
    return {
      errors: data.errors,
      result: {},
    };
  }
  return data.result;
}

export async function updateExtendedTrialEnvelopes() {
  const accessToken = await getAccessToken();
  const claims: any = jwt_decode(accessToken);
  const { data } = await axios.put(
    `${accountApiUrl}/v3/users/${claims.OktaUserId || claims.uid}/trial/envelopesLeft`,
    {},
    headers(accessToken)
  );
  if (data.errors.length) {
    return {
      errors: data.errors,
      result: {},
    };
  }
  return data.result;
}

import { launchDarklyFlags } from '../../common/launchDarkly';
import { PermissionType } from '../../store/pageFrame/types';
import { isCurrentDatePastExpDate } from '../../common/utils';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { accountApi } from '../../lib/api/account';
import { useGetStripeSubscription } from '../accounts-api';

type UseHasDs3AccessResponse = {
  hasDs3Access: boolean;
  trial: any;
  isTrialExpired: boolean;
  isValidStripeSubscriptionExpired: boolean;
};

/**
 * A hook to determine if the user has ds3.* in their permissions array
 */
export function useHasDs3Access(options?: UseQueryOptions<UseHasDs3AccessResponse>) {
  const { flags } = launchDarklyFlags();
  const subscriptionRequired = flags['subscription-required'] as boolean;

  const { data: stripeSubcription } = useGetStripeSubscription();
  return useQuery(
    ['has-ds3-access'],
    async () => {
      let trial = await accountApi.getTrial();
      const memberships = await accountApi.getUsersMemberships();

      const hasDs3Permission = memberships?.subscriptions
        ?.filter((s) => s.source !== 'Forms/DS3 Trial')
        .some((s) => s.permissions.includes(PermissionType.DS3));

      const hasValidStripeSubscription =
        !!stripeSubcription &&
        new Date(stripeSubcription.validUntil) > new Date('1970-01-01') &&
        !isCurrentDatePastExpDate(stripeSubcription.validUntil);
      const hasDs3Access = !subscriptionRequired || hasDs3Permission || hasValidStripeSubscription;

      if (!hasDs3Access && !trial) {
        trial = await accountApi.createTrial();
      }

      const trialExtensions = trial?.extensions || [];
      const latestTrialExtension = trialExtensions[0];
      const isTrialExpired =
        (isCurrentDatePastExpDate(trial?.endDate?.slice(0, 10) || '') && !latestTrialExtension) ||
        latestTrialExtension?.envelopesLeft === 0;

      return {
        hasDs3Access,
        trial: trial,
        isTrialExpired,
      };
    },
    {
      ...options,
      enabled: subscriptionRequired !== undefined,
    }
  );
}

import { useState, useEffect, useMemo } from 'react';
import { ACCOUNT_ROLES } from '../lib/constants';
import { ISigner, IRecipient, IRecipientBody } from './recipients/types';
import { v4 as uuidv4 } from 'uuid';

export const chameleonElId = (num: string) => {
  return `ds-chameleon-${num}`;
};

export const checkIfRecipientIsSender = (signer: ISigner | IRecipient | IRecipientBody) => {
  return signer.title?.toLowerCase() === 'me' || signer.recipientColorCounter === 0;
};

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const convertFieldTypeToQueryType = (fieldType: string): string => {
  if (fieldType === 'First Name') {
    return 'firstName';
  }
  if (fieldType === 'Last Name') {
    return 'lastName';
  }
  if (fieldType === 'Email') {
    return 'email';
  }
  return '';
};

export const createRandomId = (): string => uuidv4();

export const removeSpecialCharacters = (name: string) => {
  // this will replace all non-alphanumeric characters, except those included in the square brackets after 9, with an underscore
  const cleanName = name.replace(/[^a-zA-Z0-9_.-]/g, '_');
  return cleanName;
};

export const getUserAdminRoles = async (
  setUser: React.Dispatch<
    React.SetStateAction<{
      isBrokerAdmin: boolean;
      isGroupAdmin: boolean;
    }>
  >,
  getUsersGroups
) => {
  const groupsRes = await getUsersGroups();

  if (groupsRes.userGroups && groupsRes.userGroups.length > 0 && groupsRes.access && groupsRes.access.length > 0) {
    const parentGroups = groupsRes.userGroups?.filter((g) => !g.parentGroupId).map((g) => g._id);

    const parentGroupRoles = groupsRes.access.filter((a) => parentGroups.includes(a.groupId)).map((a) => a.roleId);

    setUser({
      isBrokerAdmin: parentGroupRoles.includes(ACCOUNT_ROLES.BROKER_ADMIN),
      isGroupAdmin: parentGroupRoles.includes(ACCOUNT_ROLES.GROUP_ADMIN),
    });
  } else {
    setUser({
      isBrokerAdmin: false,
      isGroupAdmin: false,
    });
  }
};

export const isSkySlopeMobileApp = (): boolean => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return useMemo(() => Boolean(userAgent.match(/MobileApp.*SkySlope/i)), [userAgent]);
};

export const postWindowMessage = (
  body:
    | {
        header_text: string;
        back_button_type: string;
        should_back_button_exit: boolean;
        button_text: string;
      }
    | { status: string; message?: string }
) => {
  window.webkit.messageHandlers.mobileAppListener.postMessage(JSON.stringify(body));
};

export const isAppRunningInIframe = (): boolean => window.location !== window.parent.location;

export const isCurrentDatePastExpDate = (expirationDate: string) => {
  // expirationDate is currently either subscription.validUntil or trial.endDate
  // and is in the format 'yyyy-mm-dd'
  const currentDate = new Date().toISOString().slice(0, 10);
  return currentDate >= expirationDate;
};

export const formatDateToYYYYMMDD = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

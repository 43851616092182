import { useTrialBanner } from './use-trial-banner';
import { useHasDs3Access } from './use-has-ds3-access';

/**
 * A hook to aggregate & return the user's trial and subscription information
 */
export function useDS3Access() {
  const { data, isLoading, isError } = useHasDs3Access();
  const isPendingAccessCheck = isLoading || isError;
  const { hasDs3Access, trial, isTrialExpired } = data || {};

  const { showBanner, dismissBanner, daysLeft } = useTrialBanner({
    trialEndDate: trial?.endDate,
    hasDs3Access,
    isPendingAccessCheck,
  });

  return {
    trial,
    isLoading: isPendingAccessCheck,
    isTrialExpired,
    daysLeft,
    showBanner,
    dismissBanner,
    /** Derived by if any of the folllowing are true:
     * The subscription-required flag is disabled
     * They're a standalone user with a Forms subscription
     * They have 'ds3.* in their permissions array */
    hasDs3Access,
    isEligibleForExtension: isTrialExpired && !trial?.extensions?.length,
    latestTrialExtension: trial?.extensions?.[0],
  };
}

import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import { colors } from '@skyslope/mache';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';
import { ReactComponent as LockOutImage } from '../../images/lockoutkey.svg';
import { SUBSCRIPTION_PRICE_OPTIONS, SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED } from '../../lib/constants';
import * as accountApi from '../../lib/api/account/accountApi';
import * as actions from '../../store/pageFrame/actions';
import { setLoading, updateUserSubscription } from '../../store/pageFrame/actions';
import { HeaderContext } from '../../context/header-context';
import { isSkySlopeMobileApp } from '../../common/utils';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import { ICheckoutBody } from '../../store/templateCreate/types';
import { useBreakPointObserver } from '../../hooks/useBreakPointObserver';
import { useDS3Access } from '../../hooks/trial/use-ds3-access';
import { useExtendTrial } from '../../hooks/accounts-api';

const useStyles = makeStyles(() => ({
  checked: {
    color: `${colors.blue[800]} !important`,
  },
  containerMobile: (prop: { headerHeight: number }) => ({
    overflowY: 'auto',
    width: '100vw',
    paddingTop: `${prop.headerHeight + 40}px`,
    marginTop: '0px',
    paddingBottom: '206px', // enough padding to make sure the button is not covered by the bottom menu and button
    height: `calc(100vh - ${prop.headerHeight}px - 184px)`, // 72px is the height of the button + 112px extra for iphones
  }),
}));

const breakPoints = {
  mobile: '(max-width:579px)',
  desktop: '(min-width:580px)',
};

interface LocationState {
  url?: string;
}

const SubscriptionPage = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const [priceValue, setPriceValue] = useState('');
  const history = useHistory<LocationState>();
  const headerContext = useContext(HeaderContext);
  const breakPoint = useBreakPointObserver(breakPoints);

  const hideMonthlySubscription = flags && flags['hide-monthly-plan'];
  const stripePriceIncreaseEnabled = flags && flags['stripe-price-increase-enabled'];

  const priceOptions = stripePriceIncreaseEnabled ? SUBSCRIPTION_PRICE_OPTIONS : SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED;

  const params = new URLSearchParams(window.location.search);
  const coupon = params.get('c');
  const plan = params.get('p');
  const directToCheckout = coupon && plan;
  const { isEligibleForExtension } = useDS3Access();
  const extendFreeTrial = useExtendTrial();

  useEffect(() => {
    headerContext.setContent(<div />);
    if (directToCheckout) {
      dispatch(setLoading(true));
      handlePaymentRedirect();
    }
  }, []);

  useEffect(() => {
    if (hideMonthlySubscription) {
      setPriceValue(priceOptions[0].priceId);
    }
  }, [flags]);

  const classes = useStyles({ headerHeight: 73 });
  const dispatch = useDispatch();
  const LockOutBackDrop = () => {
    return (
      <div className="w-full bg-grey-100">
        <LockOutImage className="absolute right-[10%] top-[12%]" />
      </div>
    );
  };

  const isSmallScreen = breakPoint === 'mobile';
  const isMobileApp = isSkySlopeMobileApp();
  const isMobileAppOrSmallScreen = isMobileApp || isSmallScreen;
  const handlePaymentRedirect = async () => {
    let urlPriceId = '';
    if (plan === 'annual') {
      urlPriceId = priceOptions[0].priceId;
    }
    // if no url is passed in state then redirect to envelope managment
    const body: ICheckoutBody = {
      redirectUrl: history.location?.state?.url ?? `http://${window.location.host}/envelopes`,
      priceId: urlPriceId || priceValue,
    };
    if (coupon) body.coupon = coupon;
    // get the url to navigate to Stripe
    const res = await accountApi.checkout(body);
    if (res.status !== 200) {
      dispatch(actions.errorToast('Unable to continue to payments page. Please try again.'));
      dispatch(setLoading(false));
      throw Error(`There was an error in the Stripe Checkout: ${res.status} `);
    }

    window.location.replace(res.result.url);
  };

  const handleTrialExtensionClick = async () => {
    await extendFreeTrial.mutateAsync();
    const redirectUrl = history.location?.state?.url ?? `http://${window.location.host}/envelopes`;
    window.location.replace(redirectUrl);
  };

  const title = (
    <ModalTitle>
      Unlock full access to DigiSign
      <span role="img" aria-label="rocket" className={`${!isMobileAppOrSmallScreen ? 'absolute' : ''} px-2 py-0`}>
        🚀
      </span>
    </ModalTitle>
  );
  const classNameFormControl = (i: number) =>
    `flex-row-reverse min-h-[100px] rounded-[5px] justify-between pl-4 mt-4 mb-3 mx-0 w-full lex-1 m-[5px] px-2.5 py-0 border-[thin] text-grey-700  border-grey-700 ${
      priceValue === priceOptions[i].priceId ? ' border-solid' : 'border-dotted '
    }`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceValue(event.target.value);
  };

  const handleAlreadyPaidClick = () => {
    dispatch(updateUserSubscription());
  };

  const content = (
    <div id="paymentContainer">
      <div className="mt-2.5 mb-8 mx-0">
        <ModalText>
          Want more? It's not too late&mdash;start a DigiSign subscription to unlock all the bells and whistles. In the
          meantime, you can still access your existing complete DigiSign envelopes.
        </ModalText>
      </div>
      <FormControl className="w-full" component="fieldset">
        {!hideMonthlySubscription && (
          <FormLabel className="text-grey-700" component="legend">
            Select your plan below:
          </FormLabel>
        )}
        <RadioGroup
          className={
            hideMonthlySubscription
              ? 'flex-row min-h-[140px] justify-around pb-0 mx-0'
              : 'flex-row min-h-[140px] justify-around pb-0 pt-4 mx-0'
          }
          aria-label="price"
          name="price"
          value={priceValue}
          onChange={handleChange}
          data-testid="price_options"
        >
          <FormControlLabel
            className={classNameFormControl(0)}
            value={priceOptions[0].priceId}
            data-testid="option-1"
            checked={priceValue === priceOptions[0].priceId}
            control={<Radio classes={{ checked: classes.checked }} color="primary" />}
            label={
              <div>
                <strong>{priceOptions[0].label.description}</strong>
                <br />
                {priceOptions[0].label.price}
              </div>
            }
          />
          <Divider className="hidden sm:block" orientation="vertical" variant="middle" flexItem />
          {!hideMonthlySubscription && (
            <FormControlLabel
              className={classNameFormControl(1)}
              value={priceOptions[1].priceId}
              data-testid="option-2"
              control={<Radio classes={{ checked: classes.checked }} color="primary" />}
              label={
                <div>
                  <strong>{priceOptions[1].label.description}</strong>
                  <br />
                  {priceOptions[1].label.price}
                </div>
              }
            />
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
  const actionButtons = !isMobileAppOrSmallScreen ? (
    <>
      {isEligibleForExtension ? (
        <ModalCancelButton variant="text" onClick={handleTrialExtensionClick} aria-label="keep envelope">
          Extend Free Trial
        </ModalCancelButton>
      ) : (
        <ModalCancelButton variant="outlined" onClick={handleAlreadyPaidClick} aria-label="keep envelope">
          I've Already Paid
        </ModalCancelButton>
      )}
      <ModalConfirmationButton
        onClick={handlePaymentRedirect}
        aria-label="confirm cancel envelope"
        disabled={!priceValue}
      >
        Unlock Access
      </ModalConfirmationButton>
    </>
  ) : (
    <Button
      className={`fixed w-full min-h-[72px] m-0 p-0 bottom-0 left-0 rounded-none hover:brightness-[85%] active:brightness-[80%] ${
        !priceValue ? '' : 'bg-gradient-to-r from-blue-800 to-blue-400 bg-transparent'
      } `}
      color="primary"
      variant="contained"
      onClick={handlePaymentRedirect}
      endIcon={<ChevronRightIcon />}
      data-spec="modal-confirmation-button"
      disabled={!priceValue}
    >
      Unlock Access
    </Button>
  );
  return (
    <div>
      {directToCheckout ? (
        <></>
      ) : (
        <Modal
          modalStyles={{
            position: 'absolute',
            left: '0%',
            top: '0',
            padding: isMobileAppOrSmallScreen ? '0' : '40px 20px 0px',
            maxWidth: '560px',
            minWidth: '336px',
            maxHeight: 'none',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            actionButtonsStyle: {
              paddingBottom: '40px',
              paddingLeft: '40px',
            },
            margin: isMobileAppOrSmallScreen ? '0 0 0 0' : '10% 17%',
            titlePadding: isMobileAppOrSmallScreen ? '0px 24px 15px' : '',
            modalContentStyle: isMobileAppOrSmallScreen ? { padding: '0 12px' } : {},
          }}
          hideCloseButton
          title={title}
          content={content}
          actionButtons={actionButtons}
          backdropComponent={LockOutBackDrop}
          modalContainerClassName={isMobileAppOrSmallScreen ? classes.containerMobile : ''}
          dialogClassName={`${isMobileAppOrSmallScreen ? 'z-[1]' : 'bg-grey-100 overflow-y-auto'}`}
        />
      )}
    </div>
  );
};

export default withLaunchDarkly(SubscriptionPage);

import React from 'react';
import { ITrialBannerStorageObject } from '../../store/pageFrame/types';

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export function useTrialBanner(args: { trialEndDate?: string; hasDs3Access?: boolean; isPendingAccessCheck: boolean }) {
  const { trialEndDate, hasDs3Access, isPendingAccessCheck } = args;
  const [daysLeft, setDaysLeft] = React.useState(0);
  const [showBanner, setShowBanner] = React.useState(false);
  const { bannerStorage, setTrialBannerObjInLocalStorage } = useTrialBannerLocalStorage();

  function dismissBanner() {
    setShowBanner(false);
    setTrialBannerObjInLocalStorage({ dismissed: true, dismissalExpiration: Date.now() + ONE_DAY_IN_MILLIS });
  }

  React.useEffect(() => {
    if (!trialEndDate || hasDs3Access || isPendingAccessCheck) {
      setShowBanner(false);
      setTrialBannerObjInLocalStorage(null);
      return;
    }

    const remainingDays = Math.floor((new Date(trialEndDate).getTime() - Date.now()) / ONE_DAY_IN_MILLIS);
    setDaysLeft(remainingDays);

    const dismissalIsExpired = bannerStorage?.dismissalExpiration && bannerStorage.dismissalExpiration < Date.now();
    if (!bannerStorage?.dismissed || dismissalIsExpired) {
      setShowBanner(true);
      setTrialBannerObjInLocalStorage(null);
    }
  }, [trialEndDate, hasDs3Access, bannerStorage, setTrialBannerObjInLocalStorage]);

  return { showBanner, dismissBanner, daysLeft };
}

const TRIAL_BANNER_LOCAL_STORAGE_KEY = '_trialBanner';

function useTrialBannerLocalStorage() {
  const [bannerStorage, setBannerStorage] = React.useState<ITrialBannerStorageObject | null>(() =>
    JSON.parse(localStorage.getItem(TRIAL_BANNER_LOCAL_STORAGE_KEY) || 'null')
  );

  const setTrialBannerObjInLocalStorage = React.useCallback((obj: ITrialBannerStorageObject | null) => {
    setBannerStorage(obj);
    if (obj) {
      localStorage.setItem(TRIAL_BANNER_LOCAL_STORAGE_KEY, JSON.stringify(obj));
    } else {
      localStorage.removeItem(TRIAL_BANNER_LOCAL_STORAGE_KEY);
    }
  }, []);

  return { bannerStorage, setTrialBannerObjInLocalStorage };
}
